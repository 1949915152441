import { Link } from '@remix-run/react'
import { CircleCheck } from 'lucide-react'
import { Badge } from '#app/components/ui/badge.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { Card } from '#app/components/ui/card.tsx'
import { FAQ } from './faq'
import Testimonials from './testimonials'

export function PricingBeta() {
	return (
		<section
			id="preco"
			className="bg-gradient-to-b from-gray-100 to-white px-2 py-16 dark:from-muted/30 dark:to-background sm:py-20 md:-mx-4"
		>
			<div className="mx-auto max-w-4xl text-center">
				<h2 className="inline-block rounded-full bg-orange-100 px-4 py-2 text-sm font-semibold text-orange-600 dark:bg-orange-900 dark:text-orange-300 md:text-base">
					Anunciantes
				</h2>
				<p className="mt-6 text-4xl font-bold tracking-tight text-primary dark:text-white sm:text-5xl">
					Venda seu carro elétrico no lugar certo.
				</p>{' '}
				<p className="mt-6 text-center max-w-2xl mx-auto text-lg font-normal text-muted-foreground">
					Anuncie no <span className="font-bold">portal exclusivo para carros elétricos do Brasil</span> e conecte-se diretamente com <span className="font-bold">compradores qualificados</span>.
				</p>
			</div>{' '}
			<div className="mt-16 flex flex-col gap-8 md:flex-row md:justify-center">
				<Card className="relative w-full max-w-md rounded-xl bg-gradient-to-br from-[#1a3d5e] to-[#2c5a8a] p-8 text-white shadow-xl">
					<div className="absolute -top-4 left-1/2 -translate-x-1/2 transform">
						<Badge
							variant="secondary"
							className="bg-[#1a3d5e] px-4 py-1 text-sm font-bold text-white"
						>
							Mais popular
						</Badge>
					</div>
					<div className="text-center">
						<div className="mb-6 flex justify-center">
							<img
								src="/img/logo.webp"
								alt="Logo"
								className="h-20 w-20 rounded-full border-4 border-white shadow-lg"
								width="80"
								height="80"
								style={{ aspectRatio: '1', objectFit: 'cover' }}
							/>
						</div>
						<h3 className="mb-4 text-2xl font-bold">Crédito para anúncio</h3>
						<div className="mb-6 text-5xl font-bold">R$ 99</div>
						<Link to="/usuario/carregados/carros/creditos">
							<Button className="w-full rounded-lg bg-white font-semibold text-[#1a3d5e] transition-colors hover:bg-gray-100">
								Anunciar agora
							</Button>
						</Link>
					</div>
					<div className="mt-8">
						<h4 className="mb-4 text-xl font-semibold">Inclui:</h4>
						<ul className="space-y-4">
							{[
								'Tempo ilimitado. Fique até vender!',
								'Retorno para o topo da página após 30, 60 e 90 dias.',
								'Criador de anúncio com IA. Crie o anúncio perfeito e venda rápido.',
								'Destaque no único portal exclusivo para carros elétricos e híbridos do Brasil.',
							].map((item, index) => (
								<li key={index} className="flex items-start gap-3">
									<div className="flex-shrink-0 text-green-300">
										<CircleCheck size={24} />
									</div>
									<span>{item}</span>
								</li>
							))}
						</ul>
					</div>
				</Card>
				<Card className="relative w-full max-w-md rounded-xl bg-white p-8 shadow-xl dark:bg-muted/30">
					<div className="absolute -top-4 left-1/2 -translate-x-1/2 transform">
						<Badge
							variant="secondary"
							className="bg-[#1a3d5e] px-4 py-1 text-sm font-bold text-white"
						>
							Melhor negociação
						</Badge>
					</div>
					<div className="text-center">
						<h3 className="mb-6 text-2xl font-bold text-primary dark:text-white">
							Pacote de créditos para anúncios
						</h3>
						<p className="mb-8 text-muted-foreground">
							Para descontos especiais em pacotes acima de 5 anúncios, entre em
							contato!
						</p>
						<Link to="mailto:contato@carregados.com.br">
							<Button className="w-full rounded-lg bg-[#219EBC] font-semibold text-white transition-colors hover:bg-[#1c8aa6] md:mt-[57px]">
								Fale Conosco
							</Button>
						</Link>
					</div>
				</Card>
			</div>
			<Testimonials />
			<FAQ />
		</section>
	)
}
