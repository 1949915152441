import { Link } from '@remix-run/react'

export function Tools() {
	return (
		<section
			id="utilidades"
			className="bg-gradient-to-b from-gray-50 to-white px-2 py-20 dark:from-gray-900 dark:to-gray-800 md:-mx-4 lg:px-8"
		>
			<div className="mx-auto max-w-6xl text-center">
				<h2 className="inline-block rounded-full bg-orange-100 px-4 py-2 text-sm font-semibold text-orange-600 dark:bg-orange-900 dark:text-orange-300 md:text-base">
					Utilidades
				</h2>
				<p className="mt-6 text-2xl font-bold tracking-tight text-primary dark:text-white sm:text-4xl lg:text-5xl">
					Entre na revolução elétrica!
				</p>
				<p className="mx-auto mt-4 max-w-2xl text-lg text-muted-foreground dark:text-gray-300">
					  <span className="font-bold">Inspire-se</span> com nossos <span className="font-bold">guias</span> e <span className="font-bold">notícias</span> sobre carros elétricos.  
				</p>
				<div className="mx-auto mt-12 w-full">
					<div className="grid w-full grid-cols-1 gap-8 px-2 sm:grid-cols-2 lg:grid-cols-3">
						<ToolCard
							to="quanto-tempo-demora-para-carregar-um-carro-eletrico"
							imgSrc="/img/quanto-tempo-demora.webp"
							imgAlt="Carro elétrico carregando em um wallbox caseiro."
							title="Quanto tempo demora para carregar um carro elétrico?"
						/>
						<ToolCard
							to="quanto-custa-para-carregar-um-carro-eletrico"
							imgSrc="/img/quanto-custa-carregar.webp"
							imgAlt="Carro elétrico carregando em um ponto de carregamento público."
							title="Quanto custa para carregar um carro elétrico?"
						/>
						<ToolCard
							to="glossario"
							imgSrc="/img/glossario.webp"
							imgAlt="Um robô pensando em um glossário de termos elétricos."
							title="Glossário Elétrico"
						/>
					</div>
				</div>
			</div>
		</section>
	)
}

function ToolCard({
	to,
	imgSrc,
	imgAlt,
	title,
}: {
	to: string
	imgSrc: string
	imgAlt: string
	title: string
}) {
	return (
		<Link
			to={to}
			className="group flex flex-col overflow-hidden rounded-lg bg-white shadow-lg transition-all hover:shadow-xl dark:bg-gray-800"
		>
			<div className="aspect-w-16 aspect-h-9 overflow-hidden">
				<img
					src={imgSrc}
					alt={imgAlt}
					loading="lazy"
					className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
				/>
			</div>
			<div className="flex flex-1 flex-col justify-between p-6">
				<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
					{title}
				</h3>
				<div className="mt-4 flex items-center">
					<span className="font-medium text-primary transition-colors duration-300 group-hover:text-primary/80 dark:text-primary-foreground dark:group-hover:text-primary-foreground/80">
						Leia mais
					</span>
					<svg
						className="ml-2 h-4 w-4 text-blue-600 transition-transform duration-300 group-hover:translate-x-1 dark:text-blue-400"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M9 5l7 7-7 7"
						/>
					</svg>
				</div>
			</div>
		</Link>
	)
}
