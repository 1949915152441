import { Avatar, AvatarFallback } from '#app/components/ui/avatar.tsx'
import { Card, CardContent, CardHeader } from '#app/components/ui/card.tsx'
import { Star } from 'lucide-react'

const Testimonial = ({
	name,
	car,
	review,
	date,
	time,
}: {
	name: string
	car: string
	review: string
	date: string
	time: string
}) => (
	<Card className="w-full shadow-md">
		<CardHeader className="flex flex-row items-center gap-4">
			<Avatar className="h-16 w-16">
				<AvatarFallback>
					{name
						.split(' ')
						.map((n) => n[0])
						.join('')}
				</AvatarFallback>
			</Avatar>
			<div className="flex flex-col">
				<h3 className="text-lg font-semibold">{name}</h3>
				<p className="text-sm text-muted-foreground">{car}</p>
			</div>
		</CardHeader>
		<CardContent>
			<div className="mb-2 flex">
				{[...Array(5)].map((_, i) => (
					<Star key={i} className="h-5 w-5 fill-yellow-500 text-yellow-500" />
				))}
			</div>
			<p className="mb-4 text-sm text-muted-foreground">{review}</p>
			<div className="flex items-center justify-end text-xs text-primary">
				<span className="font-semibold">Venda confirmada em {time}</span>
			</div>
		</CardContent>
	</Card>
)

export default function Component() {
	const testimonials = [
		{
			name: 'Tiago Neves',
			car: 'Tesla Model 3 Performance',
			review:
				'Como empresário, valorizo muito eficiência e praticidade. O Carregados superou todas as expectativas! Em apenas 3 semanas, consegui vender meu Tesla Model 3 Performance pelo preço que eu queria. A plataforma é intuitiva e o suporte foi excepcional em todo o processo. Definitivamente a melhor opção para quem quer vender um carro elétrico no Brasil.',
			date: 'Maio 15, 2023',
			time: '21 dias',
		},
		{
			name: 'Ana Paula Rossi',
			car: 'GWM Ora 03 500 Pro',
			review:
				'Estava apreensiva em vender meu carro elétrico online, mas o Carregados tornou tudo muito simples e seguro. A equipe me auxiliou com a precificação e documentação necessária. Em menos de um mês, fechei negócio com um ótimo comprador. A transparência e profissionalismo da plataforma fazem toda a diferença!',
			date: 'Junho 3, 2023',
			time: '25 dias',
		},
		{
			name: 'Sérgio Oliveira',
			car: 'BYD Dolphin Plus 2023',
			review:
				'Impressionante como o Carregados entende o mercado de elétricos! Anunciei meu BYD Dolphin Plus e recebi várias propostas de compradores realmente interessados. O diferencial é que a plataforma atrai um público específico que conhece e valoriza carros elétricos. Vendi em 18 dias por um valor justo e todo o processo foi muito profissional.',
			date: 'April 22, 2023',
			time: '18 dias',
		},
	]

	return (
		<div className="container mx-auto px-0 py-20 sm:px-4">
			<h2 className="mb-8 text-center text-4xl font-bold">
				Quem vendeu?
			</h2>
			<div className="mx-auto grid max-w-6xl grid-cols-1 gap-6 md:grid-cols-2 md:px-0 lg:grid-cols-3">
				{testimonials.map((testimonial, index) => (
					<Testimonial key={index} {...testimonial} />
				))}
			</div>
		</div>
	)
}
