import { Link } from '@remix-run/react'
import { Check } from 'lucide-react'
import { Button } from '#app/components/ui/button.tsx'

export function MapMain() {
	return (
		<section
			id="mapa"
			className="bg-gradient-to-b from-gray-50 to-white px-2 py-20 dark:bg-muted/30 md:-mx-4 lg:px-8"
		>
			<div className="mx-auto max-w-6xl text-center">
				<h2 className="inline-block rounded-full bg-orange-100 px-4 py-2 text-sm font-semibold text-orange-600 dark:bg-orange-900 dark:text-orange-300 md:text-base">
					Estações de Carregamento
				</h2>
				<p className="mt-6 text-2xl font-bold tracking-tight text-primary dark:text-white sm:text-4xl lg:text-5xl">
					Mapa de Carregadores
				</p>{' '}
				<p className="mx-auto mt-4 max-w-2xl text-lg text-muted-foreground dark:text-gray-300">
					O <span className="font-bold">mapa de estações de carregamento de carros elétricos mais completo</span> do Brasil.
				</p>
				<div className="mx-auto mt-4 flex w-full flex-col-reverse gap-6 sm:mt-6 md:flex-row md:justify-center lg:mt-8">
					<div className="flex flex-col md:items-center md:justify-center">
						<ul className="flex flex-col gap-6 pb-10 text-lg">
							<li className="flex items-start space-x-3">
								<Check className="mt-1 h-5 w-5 flex-shrink-0 text-[#f58b14]" />
								<span className="text-left font-medium">
									Mapa com{' '}
									<span className="font-bold text-primary">mais de 10.000</span>{' '}
									estações de carregamento em todo o Brasil
								</span>
							</li>
							<li className="flex items-start space-x-3">
								<Check className="mt-1 h-5 w-5 flex-shrink-0 text-[#f58b14]" />
								<span className="text-left font-medium">
									Status de{' '}
									<span className="font-bold text-primary">
										disponibilidade
									</span>{' '}
									para cada estação
								</span>
							</li>
							<li className="flex items-start space-x-3">
								<Check className="mt-1 h-5 w-5 flex-shrink-0 text-[#f58b14]" />
								<span className="text-left font-medium">
									<span className="font-bold text-primary">
										Avaliações detalhadas
									</span>{' '}
									e fotos recentes compartilhadas pela comunidade
								</span>
							</li>
							<li className="flex items-start space-x-3">
								<Check className="mt-1 h-5 w-5 flex-shrink-0 text-[#f58b14]" />
								<span className="text-left font-medium">
									Informações completas:{' '}
									<span className="font-bold text-primary">
										tipos de conectores, tarifas, potência
									</span>{' '}
									e muito mais
								</span>
							</li>
						</ul>

						<Link prefetch="intent" to="/mapa">
							<Button className="font-bold capitalize">
								ver mapa de carregadores
							</Button>
						</Link>
					</div>
					<Link prefetch="intent" to="/mapa">
						<img
							loading="lazy"
							className="aspect-square w-full rounded-lg shadow-md transition-transform duration-300 md:max-w-[400px] md:hover:scale-105"
							src="/img/mapa-main.webp"
							alt="Mapa de carregadores de carros elétricos."
						/>
					</Link>
				</div>
			</div>
		</section>
	)
}
