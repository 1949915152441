import { Link } from '@remix-run/react'

import { Icon } from './ui/icon.tsx'
import { Marquee } from './ui/marquee.tsx'

export function Brands({ totalNumberOfCars }: { totalNumberOfCars: number }) {
	return (
		<section className="bg-gradient-to-tr from-gray-50 via-gray-200 to-white py-14 dark:from-gray-900 dark:via-gray-800 dark:to-gray-700 sm:py-16 md:-mx-4">
			<div className="mx-auto w-full px-2 lg:px-8">
				<Marquee
					className="gap-[3rem] pb-8 text-gray-700 dark:text-white md:py-4 md:pb-0"
					innerClassName="md:gap-[3rem] md:[--gap:3rem] gap-[2rem] --gap:2rem"
					fade={true}
					pauseOnHover={false}
					reverse={true}
				>
					{/*Audi*/}
					<div className="flex items-center justify-center sm:w-[150px]">
						<Link
							prefetch="intent"
							to="/carros?marca=audi"
							aria-label="Veja os carros da marca Audi"
						>
							<Icon
								name="audi"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*BYD*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=byd"
							aria-label="Veja os carros da marca BYD"
						>
							<Icon
								name="byd"
								className="text-8xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*BMW*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=bmw"
							aria-label="Veja os carros da marca BMW"
						>
							<Icon
								name="bmw"
								className="text-8xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Chevrolet*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=chevrolet"
							aria-label="Veja os carros da marca Chevrolet"
						>
							<Icon
								name="chevrolet"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Volvo*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=volvo"
							aria-label="Veja os carros da marca Volvo"
						>
							<Icon
								name="volvo"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Jaguar*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=jaguar"
							aria-label="Veja os carros da marca Jaguar"
						>
							<Icon
								name="jaguar"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Tesla*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=tesla"
							aria-label="Veja os carros da marca Tesla"
						>
							<Icon
								name="tesla"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Porsche*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=porsche"
							aria-label="Veja os carros da marca Porsche"
						>
							<Icon
								name="porsche"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Renault*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=renault"
							aria-label="Veja os carros da marca Renault"
						>
							<Icon
								name="renault"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Nissan*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=nissan"
							aria-label="Veja os carros da marca Nissan"
						>
							<Icon
								name="nissan"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Mini*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=mini"
							aria-label="Veja os carros da marca Mini"
						>
							<Icon
								name="mini"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Mercedez*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=mercedes-benz"
							aria-label="Veja os carros da marca Mercedes-Benz"
						>
							<Icon
								name="mercedez"
								className="text-7xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
				</Marquee>
				<div className="mx-auto max-w-5xl text-center">
					<h2 className="mt-8 text-2xl font-bold tracking-tight text-primary dark:text-white sm:text-2xl md:text-3xl lg:text-4xl lg:leading-tight">
						Encontre o carro elétrico perfeito!  
					</h2>
					<p className="mt-4 text-lg text-gray-600 dark:text-gray-300 sm:text-lg md:text-xl lg:text-2xl">
						Mais de{' '}
						<span className="font-bold text-[#f58b14]">
							{totalNumberOfCars}
						</span>{' '}
						opções disponíveis, esperando por você.
					</p>
				</div>
				<Link
					to="/carros"
					className="mt-8 flex w-full items-center justify-center rounded-full bg-[#f58b14] px-8 py-3 text-center text-lg font-semibold text-white transition-all hover:bg-[#e07c0f] focus:outline-none focus:ring-2 focus:ring-[#f58b14] focus:ring-offset-2 md:mx-auto md:w-fit"
				>
					Ver Todos os Carros
				</Link>
			</div>
		</section>
	)
}
